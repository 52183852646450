import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { FaAngleRight } from "react-icons/fa";
import "./ApplyNow.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ApplyNow = () => {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    axios
      .post("https://gabroad-server.vercel.app/applicant", data)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));

    toast.success(`Thank You Successfully applied`);
  };
  return (
    <div>
      <div className="mt-14">
        <h1 className="text-4xl text-primary font-medium text-start lg:ml-40">
          Student<span className="text-secondary"> Application Form</span>
        </h1>
        <p className="text-justify lg:ml-40 mt-5 lg:w-1/2">
          We will be in touch within 24 hours to arrange your initial
          consultation with ACADEMIC EDUCATION office (Face to Face or Virtual)
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="justify-center">
          <div className=" border-4  border-black-100 shadow-2xl lg:w-9/12 lg:ml-40 mt-20 mb-16">
            <div>
              <h4 className="text-2xl font-medium mt-6 text-start lg:ml-36">
                Application Form
              </h4>
            </div>

            <div class="divider"></div>

            <div className="grid grid-cols-1 lg:grid-cols-2 mt-16 lg:ml-36">
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text"> Name</span>
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="input input-bordered w-full max-w-xs"
                  {...register("name", {
                    required: {
                      message: "First Name is Required",
                    },
                  })}
                />
              </div>
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Email</span>
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="input input-bordered w-10/12 "
                  {...register("email", {
                    required: {
                      message: "email is Required",
                    },
                  })}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 mt-16 lg:ml-36">
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Date Of Birth</span>
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="input input-bordered w-full max-w-xs"
                  {...register("birthdate", {
                    required: {
                      message: "Last Name is Required",
                    },
                  })}
                />
              </div>
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Phone</span>
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="input input-bordered w-full max-w-xs"
                  {...register("phone", {
                    required: {
                      message: "Phone number is Required",
                    },
                  })}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 mt-16 lg:ml-36">
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">SSC GPA</span>
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="input input-bordered w-full max-w-xs"
                  {...register("ssc", {
                    required: {
                      message: "Passport Number is Required",
                    },
                  })}
                />
              </div>
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Passing Year</span>
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="input input-bordered w-full max-w-xs"
                  {...register("sscpassYear", {
                    required: {
                      message: "Institution Name 1 is Required",
                    },
                  })}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 mt-16 lg:ml-36">
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">HSC GPA</span>
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="input input-bordered w-full max-w-xs"
                  {...register("hsc", {
                    required: {
                      message: "Passport Number is Required",
                    },
                  })}
                />
              </div>
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Passing Year</span>
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="input input-bordered w-full max-w-xs"
                  {...register("hscpassYear", {
                    required: {
                      message: "Institution Name 1 is Required",
                    },
                  })}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 mt-16 lg:ml-36">
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Honours CGPA</span>
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="input input-bordered w-full max-w-xs"
                  {...register("honsCgpa", {
                    required: {
                      message: "Institution Name 2 is Required",
                    },
                  })}
                />
              </div>
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Passing Year </span>
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="input input-bordered w-full max-w-xs"
                  {...register("passingYearHons", {
                    required: {
                      message: "Institution Name 3 is Required",
                    },
                  })}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 mt-16 mb-14 lg:ml-36">
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Masters</span>
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="input input-bordered w-full max-w-xs"
                  {...register("masters", {
                    required: {
                      message: "Study Level is Required",
                    },
                  })}
                />
              </div>
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Passing Year </span>
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="input input-bordered w-full max-w-xs"
                  {...register("mastersPassingYear", {
                    required: {
                      message: "Study Name is Required",
                    },
                  })}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 mt-16 mb-14 lg:ml-36">
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">English Language Test</span>
                </label>
                <select
                  {...register("languageTest")}
                  className="select select-bordered w-full max-w-xs"
                >
                  <option>IELTS</option>
                  <option>OIETIC</option>
                  <option>Duolingo</option>
                  <option>PTE</option>
                  <option>MOI</option>
                </select>
              </div>
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Country</span>
                </label>
                <select
                  {...register("countryName")}
                  className="select select-bordered w-full max-w-xs"
                >
                  Country
                  <option>UK</option>
                  <option>Denmark</option>
                  <option>South Korea</option>
                  <option>Ireland</option>
                  <option>Australia</option>
                  <option>Canada</option>
                  <option>USA</option>
                </select>
              </div>
            </div>
            {/* 
            <div className="mt-20 mb-16">
              <input
                type="file"
                className="file-input file-input-bordered lg:w-9/12 w-full "
              />
            </div> */}
            {/* <div className="flex lg:ml-20">
              <div class="flex items-center mb-4 mt-16 lg:ml-12">
                <label
                  for="default-checkbox"
                  class="ml-6 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Self
                </label>
                <input
                  id="default-checkbox"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 ml-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
              </div>
              <div class="flex items-center mb-4 mt-16 lg:ml-12">
                <label
                  for="default-checkbox"
                  class="ml-6 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Agent
                </label>
                <input
                  id="default-checkbox"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 ml-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
              </div>
            </div> */}

            {/* <div className=" card mt-5 mb-24 docWidth lg:ml-56  bg-base-100 shadow-xl">
              <h2 className="text-center text-xl font-medium">
                Attached Documents
              </h2>
              <div className="mt-6 ml-3 mr-3 mb-6 border rounded-2xl border-black-600 ">
                <div className="p-2">
                  <div className="flex mt-8 ml-5 justify-between">
                    <h4>Passport</h4>
                    <input
                      type="file"
                      className="file-input file-input-bordered file-input-xs w-full max-w-xs"
                    />
                  </div>

                  <div className="flex mt-5 ml-5 justify-between">
                    <h4>Graduation Certificate & Transcript </h4>
                    <input
                      type="file"
                      className="file-input file-input-bordered file-input-xs w-full max-w-xs"
                    />
                  </div>

                  <div className="flex mt-5 ml-5 justify-between">
                    <h4>HCS Certificate & Transcript </h4>
                    <input
                      type="file"
                      className="file-input file-input-bordered file-input-xs w-full max-w-xs"
                    />
                  </div>

                  <div className="flex mt-5 ml-5 justify-between">
                    <h4>SSC Certificate & Transcript</h4>
                    <input
                      type="file"
                      className="file-input file-input-bordered file-input-xs w-full max-w-xs"
                    />
                  </div>

                  <div className="flex mt-5 ml-5 justify-between">
                    <h4>IELTS Score Certificate</h4>
                    <input
                      type="file"
                      className="file-input file-input-bordered file-input-xs w-full max-w-xs"
                    />
                  </div>

                  <div className="flex mt-5 mb-6 ml-5 justify-between">
                    <h4>Other Documents</h4>
                    <input
                      type="file"
                      className="file-input file-input-bordered file-input-xs w-full max-w-xs"
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div>
            <div className=" flex justify-center mr-6 mt-16 mb-20">
              <button class=" bg-primary flex text-white rounded-full font-medium btn-md w-54">
                <span className="mt-3 ml-1">Submit Application</span>{" "}
                <FaAngleRight className="mt-4 ml-1" />
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ApplyNow;
