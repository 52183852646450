import React from 'react';

const ServicesBanner = () => {
    return (
        <div className='heights'>
        <img src="https://i.ibb.co/4TqDtd0/Frame-10.png" alt="" />
       </div>
    );
};

export default ServicesBanner;