import React from 'react';

const MissionVison = () => {
    return (
        <div className=' bg-slate-100 py-3' >
            <div className='mt-20'>
                <h1 className='text-4xl text-primary font-medium mb-10'>Our Mission <span className='text-secondary'>& Vision</span></h1>
                <p className='lg:px-48 mb-8 text-justify-ceneter'>At Dream Associates, we recognize that education is not just a means to acquire knowledge but a transformative journey that shapes the future of individuals and societies. Guided by this belief, our mission is twofold: to empower aspiring students to pursue their dreams of higher education abroad and to foster a culture of ethical and morally empowered business practices. We understand the profound impact that education can have on an individual's life trajectory. By facilitating access to quality education opportunities.</p>
            </div>
            <div className='lg:ml-80 mb-10'>
                <img  src="https://i.ibb.co/xGBZs34/Frame-33.png" alt="" />
            </div>
            <div className=' mb-16'>
                
            <p className='lg:px-52 mb-8 text-justify-center'>However, our mission extends beyond mere academic success. We believe that true success is not measured solely by academic achievements but by the values and principles that guide our actions. As such, we are deeply committed to conducting our business with the utmost integrity, transparency, and respect for ethical standards. We believe in doing well by doing good, and we strive to create a positive impact on society through our actions. Ultimately, our mission is grounded in the belief that education is a fundamental human right and a powerful force for social and economic progress.</p>
            </div>
        </div>
    );
};

export default MissionVison;