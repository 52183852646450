import React  from 'react';
import { ImBooks } from 'react-icons/im';
import { FaFlag } from "react-icons/fa";
import { FaRankingStar } from "react-icons/fa6";
import { FaRegMoneyBill1 } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import { BsBank } from "react-icons/bs";
import { Link } from 'react-router-dom';

const VersityCard = ({universities}) => {
    const {universityName, address,countryName, courseName,courseType,
          institutionType,universityCost,worldRanking} = universities;

    

    return (
        <div  className='border shadow-2xl bg-base-100 lg:mt-10 lg:ml-28 lg:mr-36 mb-16'>
              <div className='grid grid-cols-1 lg:grid-cols-2'>
                <div className='m-9 lg:ml-20 flex gap-10'>
                    <div>
                    <img className='w-24 ' src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Oxford-University-Circlet.svg/1636px-Oxford-University-Circlet.svg.png" alt="" />
                    </div>
                    <div>
                   
                        <h3 className='text-3xl font-medium text-primary mt-1'>{universityName}</h3>
                        <div className='flex gap-3 mt-5 ml-1'>
                            <img className='w-5' src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Canada_%28Pantone%29.svg/1200px-Flag_of_Canada_%28Pantone%29.svg.png" alt="" />
                            <div>
                            <p className='text-gray-400 font-medium text-xs'>{address},{countryName}</p>
                            </div>
                        </div>
                        
                        <p className='text-gray-400 font-medium flex text-xs mt-3 gap-3'> <ImBooks className=' text-xl text-black ml-1'/>{courseName}</p>
                    </div>
                </div>
                <div className='lg:mt-10 lg:ml-52'>
                <Link to="/courseDetails"> <button class="flex gap-1 bg-secondary text-white rounded-full font-medium btn-md w-36"> <span className='mt-3 ml-4'> Find More</span> <IoIosArrowForward className='mt-4 text-lg' /></button></Link>
                </div>
                </div>

                <div className='flex gap-1 mb-5 mt-3 '>

                <div className='flex gap-4 bg-blue-700 w-64'>
                    <div>
                    <FaFlag className='text-2xl text-white mt-6 ml-14' />
                    </div>
                <div>

                <h4 className='font-medium text-md text-white mt-3'>Course type</h4>
                    <h4 className='font-medium text-lg text-white'>{courseType}</h4>
                </div>
                </div>
                <div className='flex gap-4 bg-blue-700 w-80'>
                   <div>
                   <FaRankingStar className='text-2xl text-white mt-5 ml-14 ' />
                   </div>
                   <div>
                   <h4 className='font-medium text-md text-white mt-3'>World Ranking</h4>
                    <h4 className='font-medium text-lg text-white'>{worldRanking}</h4>
                   </div>
                  
                  
                    
                </div>
                <div className=' flex gap-6  bg-blue-700 w-96'>
                   
                        <div>
                        <FaRegMoneyBill1 className='text-2xl text-white mt-5 ml-14 ' />
                        </div>
                        <div>
                        <h4 className='font-medium text-md text-white mt-3'>University Cost</h4> 
                    <h4 className='font-medium text-lg text-white'>{universityCost}</h4>
                        </div>
                    
                    
                </div>
                <div className=' flex gap-6  bg-blue-700 w-60'>
                    <div>
                    <BsBank className='text-2xl text-white mt-5 ml-12' />
                    </div>
                    <div>
                    <h4 className='font-medium text-md text-white mt-3'>Institution Type</h4>
                    <h4 className='font-medium text-lg text-white'>{institutionType}</h4>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default VersityCard;