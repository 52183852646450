import React from 'react';
import Conditions from '../Conditions/Conditions';
import TearmsBanner from '../TearmsBanner/TearmsBanner';


const TearmsUse = () => {
    return (
        <div>
            <TearmsBanner></TearmsBanner>
            <Conditions></Conditions>
        </div>
    );
};

export default TearmsUse;