import React from 'react';

const StudyBannerTwo = () => {
    return (
        <div className=' mt-20 mb-16'>
        <img src="https://i.ibb.co/LCR76QY/Frame-17.jpg" alt="" />
       </div>
    );
};

export default StudyBannerTwo;