import React from "react";

const DetailsBanner = () => {
  return (
    <div className=" mt-20 mb-16">
      <img src="https://i.ibb.co/6BQ32X1/Frame-17-1.png" alt="" />
    </div>
  );
};

export default DetailsBanner;
