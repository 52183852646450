import React from 'react';

const FundBanner = () => {
    return (
        <div className=''>
        <img src="https://i.ibb.co/QbXC62j/Frame-14.png" alt="" />
       </div>
    );
};

export default FundBanner;