import React from 'react';

const EduBanner = () => {
    return (
        <div className=''>
        <img src="https://i.ibb.co/x26nTFf/Frame-15.png" alt="" />
       </div>
    );
};

export default EduBanner;