import React from 'react';

const StudyBanner = () => {
    return (
        <div className=''>
        <img src="https://i.ibb.co/yFYLmMB/Frame-12.png" alt="" />
       </div>
    );
};

export default StudyBanner;