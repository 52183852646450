import React from 'react';

const AboutBannerTwo = () => {
    return (
        <div className=' mt-20 mb-20'>
        <img src="https://i.ibb.co/6BQ32X1/Frame-17-1.png" alt="" />
       </div>
    );
};

export default AboutBannerTwo;