import React from "react";
import CourseTitle from "../CourseTitle/CourseTitle";
import Details from "../Details/Details";
import DetailsBanner from "./DetailsBanner/DetailsBanner";
import ContactUs from "../../../Shared/ContactUs/ContactUs";

const CourseDetails = () => {
  return (
    <div>
      <CourseTitle></CourseTitle>
      <Details></Details>
      <DetailsBanner></DetailsBanner>
      <ContactUs></ContactUs>
    </div>
  );
};

export default CourseDetails;
