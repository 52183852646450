import React from 'react';
import { FaAngleDown } from 'react-icons/fa';

const ProgramFilter = () => {
    return (
        <div>
        <div className="dropdown">
<button className='border border-black sm:w-60 lg:w-96 p-3 flex justify-around'>Program <FaAngleDown className=' mt-1'/> </button>

</div>
</div>
    );
};

export default ProgramFilter;