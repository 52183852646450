import React from 'react';

const TearmsBanner = () => {
    return (
        <div className='heights'>
        <img src="https://i.ibb.co/rmNBXDK/Frame-12-1.png" alt="" />
       </div>
    );
};

export default TearmsBanner;