import React from 'react';

const AboutBanner = () => {
    return (
        <div className='heights'>
        <img src="https://i.ibb.co/CHwknJv/Frame-11.png" alt="" />
       </div>
    );
};

export default AboutBanner;