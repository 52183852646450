import React from 'react';
import './Banner.css'

const Banner = () => {
    return (
       <div className=''>
        <img src="https://i.ibb.co/s3vQzGf/Frame-9.png" alt="" />
       </div>
    );
};

export default Banner;