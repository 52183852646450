import React from "react";
import { BsFillCheckSquareFill, BsFillSquareFill } from "react-icons/bs";
import { GiStairs } from "react-icons/gi";
import { BiCalendar } from "react-icons/bi";
import { FaMoneyBillAlt } from "react-icons/fa";

const Details = () => {
  return (
    <div>
      <div class="hero mt--32 mb-16 ">
        <div class="hero-content flex-col lg:flex-row-reverse mt-14 mb-16">
          <div>
            <div class="card w-96 bg-sky-50 shadow-xl h-80">
              <div class="card-body">
                <p className="flex gap-3 mt-4">
                  <BsFillCheckSquareFill className="text-primary mt-1" /> Course
                  Type: <span className="font-bold"> Full Time</span>
                </p>

                <p className="flex gap-3  mt-3">
                  <GiStairs className="text-primary mt-1" /> Course Level:{" "}
                  <span className="font-bold"> Bachelors/UG Degree</span>
                </p>

                <p className="flex gap-3  mt-3">
                  <BiCalendar className="text-primary mt-1" /> Duration:{" "}
                  <span className="font-bold"> 4 years</span>
                </p>

                <p className="flex gap-3  mt-3">
                  <FaMoneyBillAlt className="text-primary mt-1" />
                  Total Tuition Fee:{" "}
                  <span className="font-bold"> £15,500/year</span>
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="justify-center">
              <h1 class="text-start font-medium  text-3xl">About The Course</h1>

              <p class="mt-8 text-lg text-justify lg:w-10/12 ">
                Lorem ipsum dolor sit amet consectetur. Amet nec et commodo
                faucibus amet. Elementum tortor consectetur cursus ligula mattis
                posuere. Adipiscing tincidunt pellentesque sit leo nunc turpis
                egestas in nec. Etiam posuere fermentum porttitor curabitur
                malesuada. Commodo dictumst eu sed tempor. Cras a eleifend nisl
                sapien. Ipsum leo arcu a sit viverra nunc urna duis condimentum.
                Ut ipsum pretium lorem lorem ut malesuada netus aliquet. Odio in
                orci lorem vestibulum mi scelerisque sit. Lectus sodales eget in
                nullam viverra. Venenatis duis vitae mauris quis facilisis arcu
                morbi. Mauris facilisis purus vel ut sed tellus viverra libero
                ultrices. Aliquet nisl netus neque ligula mi posuere purus.
                Tortor vitae feugiat commodo ultrices dui. Ut non urna quam
                bibendum. Nisi arcu nulla faucibus egestas eu id tristique elit
                vitae. Faucibus est in varius euismod mattis. Odio vel molestie
                turpis aliquet pretium ultrices enim elit. Purus ultrices diam
                blandit at tempor purus integer volutpat.
                <span className="flex gap-5 mt-7 font-medium">
                  <BsFillSquareFill className="text-secondary mt-1" />
                  Scholarships
                </span>
                <span className="flex gap-5 mt-3 font-medium">
                  <BsFillSquareFill className="text-secondary mt-1" />
                  UCAS Registered Centre
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h1 className="text-3xl font-medium text-start lg:ml-28 ml-2 mt-16 mb-9">
          How To Apply
        </h1>
        <p className="lg:px-28 text-justify px-2 mt-6 mb-16">
          Lorem ipsum dolor sit amet consectetur. Amet nec et commodo faucibus
          amet. Elementum tortor consectetur cursus ligula mattis posuere.
          Adipiscing tincidunt pellentesque sit leo nunc turpis egestas in nec.
        </p>
      </div>

      <div>
        <h1 className="text-xl font-medium text-start lg:ml-28 ml-2 mt-16 mb-9">
          Please Visit this link for more details:{" "}
          <span className="text-secondary text-lg">
            www.sadfsghgf.com/course
          </span>
        </h1>
      </div>
    </div>
  );
};

export default Details;
