import React from 'react';
import { FaAngleDown } from 'react-icons/fa';

const CourseFilter = () => {
    return (
        <div className='ml-7'>
        <div className="dropdown">
<button className='border border-black sm:w-60 lg:w-96 p-3 flex justify-around'>Courses <FaAngleDown className=' mt-1'/> </button>

</div>
</div>
    );
};

export default CourseFilter;
