import React from 'react';

const VisaBanner = () => {
    return (
        <div className=''>
        <img src="https://i.ibb.co/tp8S6WW/Frame-13.png" alt="" />
       </div>
    );
};

export default VisaBanner;