import React from 'react';

const BannerTwo = () => {
    return (
        <div className=' mt-20 mb-16'>
        <img src="https://i.ibb.co/BPNBVxg/Frame-17.png" alt="" />
       </div>
    );
};

export default BannerTwo;