import React from "react";

const AboutAbroad = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 mt-24 mb-20 gap-16">
      <div>
        <h2 className="text-4xl font-medium text-primary">
          About <span className="text-secondary"> Dream Associates </span>
        </h2>
        <p className="lg:ml-28 lg:mt-10  text-justify lg:w-10/12 ">
          Dream Associates specializes in providing comprehensive career
          counseling services tailored to meet the unique needs and aspirations
          of each student. Our services go beyond simple advice; we offer
          personalized guidance at every stage of the educational journey. From
          initial consultations to in-depth assessments of academic strengths
          and career goals, we assist students in selecting the right country
          and university based on their individual profiles and financial
          constraints. Our expert counselors are equipped with the latest
          information on global education trends, ensuring that students receive
          accurate and up-to-date guidance to make informed decisions about
          their future.
        </p>
      </div>
      <div className="mt-6">
        <img src="https://i.ibb.co/CtxGgWZ/Frame-27-2.png" alt="" />
      </div>
    </div>
  );
};

export default AboutAbroad;
