import React from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from "react-router-dom";
import './StudyDestinations.css'

const StudyDestination = () => {
  // function SamplePrevArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{ ...style, display: "block", background: "skyBlue" }}
  //       onClick={onClick}
  //     />
  //   );
  // }

  // function SampleNextArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{ ...style, display: "", background: "darkBlue" }}
  //       onClick={onClick}
  //     />
  //   );
  // }

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 600,
    slidesToShow: 4,
    
    slidesToScroll: 3,
    initialSlide: 0,
    pauseOnHover: true,
    // prevArrow: <SamplePrevArrow />,
    // nextArrow: <SampleNextArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          gap: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          gap: true,
        },
      },
    ],
  };
 
  return (
    <div className=" mb-10 mt-20">
      <h1 className=" text-start lg:ml-16 text-4xl font-medium text-primary">Pick a <span className="text-secondary"> study Destination
        </span></h1>

           <div className=" p-6 lg:ml-6 mb-14 mt-14">
           <Slider {...settings}>

    <div className="card bg-base-100 shadow-xl">
      <figure>  
        <img className=""
          src="https://i.insider.com/5f3424f2988ee31668198a09?width=1136&format=jpeg"
          alt="Shoes"
        />
      </figure>
      <div className="card-body">
      <h2 className="card-title"><Link to="/studyCanada">Australia</Link></h2>
      <p className="text-justify">Lorem ipsum dolor sit amet consectetur. Cursus lacinia volutpat urna senectus ac sit amet. Amet proin senectus et.</p>
      </div>
    </div>
    <div className="card bg-base-100 shadow-xl">
      <figure>  
        <img className=""
          src="https://c8.alamy.com/comp/GMFNRG/toronto-ontario-canada-city-hall-nathan-phillips-square-GMFNRG.jpg"
          alt="Shoes"
        />
      </figure>
      <div className="card-body">
      <h2 className="card-title"><Link to="/studyCanada">Canada</Link></h2>
      <p className="text-justify">Lorem ipsum dolor sit amet consectetur. Cursus lacinia volutpat urna senectus ac sit amet. Amet proin senectus et.</p>
      </div>
    </div>
    <div className="card bg-base-100 shadow-xl">
      <figure>  
        <img className=""
          src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/14/10/2f/fe/united-kingdom.jpg?w=700&h=500&s=1"
          alt="Shoes"
        />
      </figure>
      <div className="card-body">
      <h2 className="card-title"><Link to="/studyCanada">Uk</Link></h2>
      <p className="text-justify">Lorem ipsum dolor sit amet consectetur. Cursus lacinia volutpat urna senectus ac sit amet. Amet proin senectus et.</p>
      </div>
    </div>
    <div className="card bg-base-100 shadow-xl">
      <figure>  
        <img className=""
          src="https://www.worldfamousthings.com/wp-content/uploads/2022/04/Berlins-Brandenburg-Gate.jpg"
          alt="Shoes"
        />
      </figure>
      <div className="card-body">
      <h2 className="card-title"><Link to="/studyCanada">Usa</Link></h2>
      <p className="text-justify">Lorem ipsum dolor sit amet consectetur. Cursus lacinia volutpat urna senectus ac sit amet. Amet proin senectus et.</p>
      </div>
    </div>
    </Slider>
  </div>
        </div>
   
  );
};

export default StudyDestination;
