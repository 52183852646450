import React from 'react';

const StudyBanner = () => {
    return (
        <div className=''>
        <img src="https://i.ibb.co/c2PNtj2/Frame-14-1.png" alt="" />
       </div>
    );
};

export default StudyBanner;