import React from "react";
import logo1 from "../../image/Logo 1.png";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedinIn,
  FaPhoneAlt,
} from "react-icons/fa";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { signOut } from "firebase/auth";
import useAdmin from "../../hooks/UseAdmin";

const Navbar = () => {
  const [user, loading, error] = useAuthState(auth);

  const logout = () => {
    signOut(auth);
    localStorage.removeItem("accessToken");
  };
  const [admin] = useAdmin(user);

  const menuItems = (
    <>
      <div></div>
      {/* <div>

<Link to="/signup"> <a class="btn w-28 text-white bg-yellow-300 font-bold rounded-full border-none">
  Sign Up
</a></Link>

</div> */}

      {/* {
     user && <li className='text-white'><Link to ="/dashboard">Dashboard</Link></li>
  } */}

      <div className="btn w-28 text-white bg-primary font-bold rounded-full border-none">
        {user ? (
          <button className="" onClick={logout}>
            Sign Out
          </button>
        ) : (
          <Link to="/login"> Log In</Link>
        )}
      </div>
    </>
  );

  const secondMenuItem = (
    <>
      <div>
        {admin && (
          <li className="">
            <Link to="/adminDashboard">Dashboard</Link>
          </li>
        )}
      </div>
    </>
  );

  return (
    <div>
      <div class="navbar bg-base-100 py-8">
        <div class="navbar-start">
          <div class="dropdown">
            <label tabindex="0" class="btn btn-ghost lg:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </label>
            <ul
              tabindex="0"
              class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 gap-4"
            >
              <div>
                <h4 className="text-blue-500  font-medium">Follow Us</h4>
                <div className="grid justify-center gap-4 mt-4">
                  <p className="text-blue-400 text-2xl">
                    <FaFacebook />
                  </p>
                  <p className="text-blue-400 text-2xl">
                    <FaTwitter />
                  </p>
                  <p className="text-blue-400 text-2xl">
                    <FaLinkedinIn />
                  </p>
                </div>
              </div>

              {menuItems}
            </ul>
          </div>
          <div class="w-36 rounded-full ml-16">
            <Link to="/">
              <img src="https://i.ibb.co/k52gD2b/Logo.png" />
            </Link>
          </div>
        </div>
        <div class="navbar-center "></div>
        <div class="navbar-end hidden lg:flex gap-2 lg:mr-16">
          <div>
            <h4 className="text-blue-500  font-medium">Follow Us</h4>
            <div className="flex gap-4 mt-4">
              <p className="text-blue-400 text-2xl">
                <FaFacebook />
              </p>
              <p className="text-blue-400 text-2xl">
                <FaTwitter />
              </p>
              <p className="text-blue-400 text-2xl">
                <FaLinkedinIn />
              </p>
            </div>
          </div>
          <div class="divider divider-horizontal"></div>
          <div>{menuItems}</div>
        </div>
      </div>
      {/* daisyui navbar */}

      <div class="navbar h-20 bg-slate-800">
        <div class="navbar-start">
          <div class="dropdown">
            <button className="btn btn-square lg:hidden text-white btn-ghost">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-5 h-5 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </button>
            <ul
              tabindex="0"
              class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-slate-800 text-white rounded-box w-56"
            >
              <li>
                <button className="btn btn-ghost text-sm">
                  <Link to="/countries">Study Destination</Link>
                </button>
              </li>
              <li>
                <button className="btn btn-ghost text-sm">
                  <Link to="/findCourse">Find Course</Link>
                </button>
              </li>
              <li>
                {" "}
                <button className="btn btn-ghost text-sm">
                  <Link to="/services">Services</Link>
                </button>
              </li>
              <li>
                <button className="btn btn-ghost text-sm">
                  <Link to="/about">About</Link>
                </button>
              </li>

              <li className="mt-4 ">{secondMenuItem}</li>

              <li>
                <div class="max-w-md mx-auto mt-3 mb-3">
                  <div class="relative flex items-center w-36 h-10 rounded-lg focus-within:shadow-lg bg-slate overflow-hidden border">
                    <div class="grid place-items-center h-full w-12 text-gray-300">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                    </div>

                    <input
                      class="peer bg-slate-800 h-full w-full outline-none text-sm text-white pr-2"
                      type="text"
                      id="search"
                      placeholder="Search here"
                    />
                  </div>
                </div>
              </li>
              <div>
                <Link to="/applyNow">
                  {" "}
                  <a class="btn btn-sm bg-secondary rounded-full border-none mt-3 mb-4 text-white">
                    Apply Now
                  </a>
                </Link>
              </div>

              <div className="mt-3 mb-4 ml-10">
                <Link to="/contact">
                  <button className="text-white flex">
                    <FaPhoneAlt className="mr-2 mt-1" /> Contact Us
                  </button>
                </Link>
              </div>
            </ul>
          </div>
        </div>
        <div class="navbar-center hidden lg:flex">
          <ul class="menu menu-horizontal px-1 mr-16 gap-7 text-white">
            <button className="btn btn-ghost text-lg">
              <Link to="/countries">Study Destination</Link>
            </button>
            <button className="btn btn-ghost text-lg">
              <Link to="/findCourse">Find Course</Link>
            </button>
            <button className="btn btn-ghost text-lg">
              <Link to="/services">Services</Link>
            </button>
            <button className="btn btn-ghost text-lg">
              <Link to="/about">About</Link>
            </button>

            {secondMenuItem}

            <li>
              <div class="max-w-md mx-auto ml-24">
                <div class="relative flex items-center w-44 h-10 rounded-lg focus-within:shadow-lg bg-slate overflow-hidden border">
                  <div class="grid place-items-center h-full w-12 text-gray-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </div>

                  <input
                    class="peer bg-slate-800 h-full w-full outline-none text-sm text-white pr-2"
                    type="text"
                    id="search"
                    placeholder="Search here"
                  />
                </div>
              </div>
            </li>
            <div>
              <Link to="/applyNow">
                {" "}
                <a class="btn btn-sm bg-secondary rounded-full border-none mt-3 mb-4 text-white">
                  Apply Now
                </a>
              </Link>
            </div>
          </ul>
        </div>

        <div className="navbar-end hidden lg:flex mr-20">
          <Link to="/contact">
            <p className="text-white flex">
              <FaPhoneAlt className="mr-9 mt-1" /> Contact Us
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
