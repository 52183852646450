import React from 'react';

const VisaBanner = () => {
    return (
        <div className=''>
        <img src="https://i.ibb.co/P4hpp9T/Frame-15-1.png" alt="" />
       </div>
    );
};

export default VisaBanner;